.link {
    color: #d8d8d8;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
    color: #d8d8d8;
}

.footerContainerV2 {
    font-family: var(--font-primary);
    background-color: #1b1b1b;
    color: white;
    border-top: 2px solid white;
    padding-top: clamp(25px, 3.12vw, 60px);;
}

.allHeadingsV2 {
    font-size: clamp(14px, 1.3vw, 25px);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    color: rgba(216, 216, 216, 0.74);
    margin: 25px 0 13px;
}

.linksCol {
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.11px;
    color: #d8d8d8;
    margin-bottom: 5px;
}

.linksCol a {
    text-decoration: none;
    color: #d8d8d8;
}

.footerTopRight {
    margin-top: 25px;
}

.footerLinks a {
    color: #d8d8d8;
}

.headingMobile {
    display: none;
}

.winzoFooterImg {
    width: 200px;
    height: 60px;
    position: relative;
    margin-left: -10px;
    cursor: pointer;
}

.socialFooterContainer {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.socialImageContainer {
    width: clamp(36px, 2.6vw, 50px);
    height: clamp(36px, 2.6vw, 50px);
    position: relative;
    margin-right: 10px;
}

.membersDataContainer {
    margin: clamp(20px, 4.16vw, 80px) 0 clamp(20px, 1.56vw, 30px);
}

.membersDataHeading {
    font-size: clamp(14px, 1.14vw, 22px);
    font-weight: 500;
    letter-spacing: -0.13px;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.paymentContainer {
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
}

.paymentContainerDesktop {
    display: flex;
}

.paymentContainerMobile {
    display: none;
}

.colMobile {
    display: none;
}

.disclaimerContainer {
    margin-top: clamp(30px, 2.86vw, 55px);
}

.disclaimerHeading {
    text-transform: uppercase;
    font-size: clamp(14px, 0.93vw, 18px);
    font-weight: 500;
    letter-spacing: -0.1px;
}

.disclaimerText {
    opacity: 0.7;
    font-size: clamp(12px, 0.93vw, 18px);
    letter-spacing: -0.22px;
    color: #fff;
}

.copyright {
    opacity: 0.7;
    font-size: 12px;
    letter-spacing: -0.08px;
}

.memberContainer {
    display: flex;
    align-items: center;
}

.memberContainer > div:nth-child(1) {
    margin-right: 20px;
}

.copyright {
    text-align: center;
    padding: 15px 0;
}

.lineSeparator, .biggerLineSeparator {
    display: none;
}

@media screen and (max-width: 767px) {
    .footerTop {
        text-align: center;
    }

    .footerTopRight {
        margin-top: 0;
    }

    .headingMobile {
        display: block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.22px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    .winzoFooterImg {
        display: none;
    }

    .socialFooterContainer {
        justify-content: space-around;
        align-items: center;
        margin-bottom: 24px;
    }

    .socialFooterContainer > a {
        margin-right: 0;
    }

    .membersDataHeading {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .memberContainer {
        justify-content: center;
    }


    .lineSeparator, .biggerLineSeparator {
        display: block;
        border: 1px solid #979797;
        opacity: 0.3;
        width: 30%;
    }

    .biggerLineSeparator {
        width: 100%;
    }

    .colMobile {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        text-align: left;
        flex-direction: column;
    }

    .allHeadingsV2, .disclaimerHeading {
        font-weight: 600;
        text-align: center;
        margin: 0px 0px 15px;
        color: #ffffff;
    }

    .linksCol {
        padding-right: 5px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
    }

    .paymentContainer {
        justify-content: center;
    }

    .paymentContainerDesktop {
        display: none;
    }

    .paymentContainerMobile {
        display: flex;
        position: relative;
        justify-content: center;
        height: 22px;
    }

    .disclaimerText {
        color: rgba(255, 255, 255, 0.85);
    }

    .copyright {
        font-size: 12px;
        padding: 15px;
    }
}