.allHeadingsV2 {
    font-size: clamp(14px, 1.3vw, 25px);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    color: rgba(216, 216, 216, 0.74);
    margin: 25px 0 13px;
}

.linksCol {
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.11px;
    color: #d8d8d8;
    margin-bottom: 5px;
}

.linksCol a {
    text-decoration: none;
    color: #d8d8d8;
}

@media screen and (max-width: 767px) {
    .footerTopLeftV2 {
        display: none;
    }

    .linksCol {
        padding-right: 5px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
    }

}